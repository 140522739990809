import styles from './index.module.less';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
export interface MarketplaceNavItem {
  text: string;
  path?: string;
  icon?: { left: string; right: string };
  dropdownItems?: MarketplaceNavItem[];
  onClick?: (item: MarketplaceNavItem) => void;
}
const NavItem = ({ item, className }: { item: MarketplaceNavItem; className?: string }) => {
  const router = useRouter();
  const decodeUrl = (url: string) => {
    return decodeURIComponent(url?.replace(/\+/g, '%20'));
  };
  const getColor = (pathStr: string) => {
    let path = decodeUrl(pathStr);
    let asPath = decodeUrl(router.asPath);
    return asPath === path ? `var(--app-primary-color)` : `var(--app-text-color)`;
  };

  const getDropdownColor = (pathStr: string) => {
    let path = decodeUrl(pathStr) === '/crisis-database' ? '/crisis-database?type=Disaster Alert' : decodeUrl(pathStr);
    let asPath = decodeUrl(router.asPath);
    return asPath === path ? `var(--app-primary-color)` : `#000`;
  };

  const handleItemClick = (e: any, item: MarketplaceNavItem) => {
    if (item?.onClick) {
      item.onClick(item);
      return;
    }
    router.push(item?.path || '/');
  };

  const renderItem = (item: MarketplaceNavItem, index?: string | number) => {
    return (
      <div
        className={classNames(styles.navItemWarp, className)}
        key={index}
        onClick={(e) => handleItemClick(e, item)}
      >
        {item.dropdownItems ? (
          <Dropdown
            menu={{
              items: item.dropdownItems.map((item, index) => {
                return {
                  key: index,
                  label: (
                    <span
                      style={{ color: getDropdownColor(item?.path) }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleItemClick(e, item);
                      }}
                    >
                      {item.text}
                    </span>
                  ),
                };
              }),
            }}
            placement="bottom"
            autoAdjustOverflow={false}
          >
            <span className={styles.navItemText} style={{ color: getColor(item?.path) }}>
              {item.path === '/crisis-database' ? (
                <>
                  {item.text} <DownOutlined style={{ marginLeft: '0px' }} />
                </>
              ) : (
                item.text
              )}
            </span>
          </Dropdown>
        ) : (
          <span className={styles.navItemText} style={{ color: getColor(item?.path) }}>
            {item.text}
          </span>
        )}
      </div>
    );
  };
  return renderItem(item, `nav-item`);
};
export default NavItem;
