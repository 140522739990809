import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { GlobalHeader, GlobalFooter } from '@/components/Layouts';
import classNames from 'classnames';
// import SellOnPietraDialog from '../components/Layouts/SellOnPietraDialog';
import './index.less';
import { useTranslation } from 'next-i18next';
import { useUserProvider } from '@/context/UserProvider';
import useScreenSize from '@/lib/hooks/utils/useScreenSize';
import { getThemeData } from '@/lib/service';
import { getPathTitle } from '@/shared/utils';

interface LayoutProps {
  /**
   * Please use next/head
   */
  head?: React.ReactNode;
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  bgColor?: string;
}

const Layout = ({ children, head, showHeader = true, showFooter = true }: LayoutProps) => {
  const { pathname, locale } = useRouter();
  // const { isMobile } = useConfigProvider();
  const { width } = useScreenSize();
  const { isLogin } = useUserProvider();
  // const [open, toggle] = useToggle(false);
  const { t } = useTranslation('common');
  const [classifies, setClassifies] = useState<{ k: string; v: string }[]>([]);

  const getClassifies = async () => {
    const res = await getThemeData(locale);
    setClassifies(res?.data?.classifies);
  };

  useEffect(() => {
    getClassifies();
  }, []);

  // app sub title
  const getNavItems = () => {
    return [
      // {
      //   text: t('Home').toLocaleUpperCase(),
      //   path: '/',
      // },
      {
        text: t('Sample Guide').toLocaleUpperCase(),
        path: '/sample-guide',
      },
      {
        text: t('Crisis Database').toLocaleUpperCase(),
        path: '/crisis-database',
        // dropdownItems: classifies?.map((item) => ({ text: item.v, path: `/crisis-database?type=${item.k}` })),
        dropdownItems: classifies?.map((item) => ({ text: item.v, path: `/${getPathTitle(item.k)}` })),
      },
      {
        text: t('Pricing').toLocaleUpperCase(),
        path: '/plan',
      },
      {
        text: 'FAQ',
        path: '/faq',
      },
    ];
  };

  const getHeaderHeight = useMemo(() => {
    if (!showHeader) {
      return 0;
    } else {
      if (width > 900) {
        if (!isLogin) {
          return `109px`;
        } else {
          return `62px`;
        }
      } else {
        return `64px`;
      }
    }
  }, [width, isLogin, showHeader]);

  return (
    <div className={'layout'}>
      {head}
      {showHeader && (
        <header className={'header'}>
          <GlobalHeader
            isMobile={width < 900}
            isMarketplaceHome={pathname === '/'}
            navItems={[...getNavItems()]}
          />
        </header>
      )}
      <main className={classNames('main')} style={{ marginTop: getHeaderHeight }}>
        {children}
      </main>
      {showFooter && (
        <footer className={'footer'}>
          <GlobalFooter />
        </footer>
      )}
    </div>
  );
};
export default Layout;
