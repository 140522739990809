import Link from 'next/link';
import { useCallback, useEffect, useState } from 'react';
import styles from './index.module.less';
import { Button, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'next-i18next';
import { useUserProvider } from '@/context/UserProvider';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useRouter } from 'next/router';
import ContentShareAlert from '@/components/Common/ShareAlert';
// import { CloseOutlined } from '@ant-design/icons';

import useScreenSize from '@/lib/hooks/utils/useScreenSize';
import PopoverInfo from '@/components/Common/PopoverInfo';

const UserContent = () => {
  const {
    isLogin,
    shareUnreadCount,
    // creditsCount,
    // onCreditsChanged,
    onOpenLoginModal,
    onChangedLoginView,
    onSignOut,
    onGetStartedClick,
  } = useUserProvider();
  const [firstLetter, setFirstLetter] = useState<string>('');
  const { t } = useTranslation('common');
  const router = useRouter();
  const [open, setOpen] = useState<boolean>(false);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const { width } = useScreenSize();

  const items: MenuProps['items'] = [
    {
      key: 'dashboard',
      label: <Link href="/dashboard">{t('Dashboard')}</Link>,
    },
    {
      key: 'settings',
      label: <Link href={'/settings'}>{t('Settings')}</Link>,
    },
    {
      key: 'billing-records',
      label: <Link href={'/billing-records'}>{t('PaymentHistory')}</Link>,
    },
    {
      key: 'logout',
      label: (
        <Link href={'/'} onClick={() => onSignOut()}>
          {t('LogOut')}
        </Link>
      ),
    },
  ];

  const loadBanaceList = () => {
    // fetchHairstyleRecordsBalanceData()
    //   .then((res) => {
    //     if (res?.code === 0) {
    //       onCreditsChanged(res?.data?.balance)
    //     }
    //   })
    //   .catch((e) => {
    //     // message.error(e?.message);
    //   });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        loadBanaceList();
        if (user?.email?.length > 0) {
          const tempLetter = user?.email[0];
          setFirstLetter(tempLetter.toLocaleUpperCase());
        } else {
          setFirstLetter('');
        }
      } else {
        setFirstLetter(undefined);
      }
    });

    return () => {
      if (isLogin) {
        unsubscribe();
      } else {
        setFirstLetter(undefined);
      }
    };
  }, [isLogin]);

  useEffect(() => {
    setOpenPopover(true);

    setTimeout(() => {
      setOpenPopover(false);
    }, 3000);
  }, []);

  const popoverContent = useCallback(() => {
    return (
      <PopoverInfo
        desc={t('Spread the word about PlanBowl and help more people.')}
        open={openPopover && width >= 900}
        setOpen={() => setOpenPopover(false)}
      >
        <img
          className={styles.shareIcon}
          src={`/images/share/header-share-icon.webp`}
          onClick={() => setOpen(true)}
        />
      </PopoverInfo>
    );
  }, [openPopover]);

  return (
    <div className={styles.content}>
      {popoverContent()}

      {firstLetter != undefined && isLogin ? (
        <div className={styles.loginDiv}>
          {/* <div className={styles.creditDiv}>
          <Image src={'/images/hairstyle/credit_icon.png'} alt={''} width={30} height={30} />
          <span className={styles.creditLabel}>{`Credit: `}</span>
          <span className={styles.creditCount}>{`${creditsCount}`}</span>
        </div> */}
          <Dropdown menu={{ items }} placement="bottomRight" autoAdjustOverflow={false}>
            <Button
              className={styles.emailIcon}
              onClick={() => {
                router.push('/dashboard');
              }}
            >
              {firstLetter}
              {shareUnreadCount > 0 && <p />}
            </Button>
          </Dropdown>
        </div>
      ) : (
        <Button
          className={styles.login}
          onClick={() => {
            onGetStartedClick('none');
            onOpenLoginModal(true);
            onChangedLoginView(true);
          }}
        >
          {`${t('SignIn_SignUp')}`}
        </Button>
      )}

      <ContentShareAlert
        openType={open}
        handleCancel={() => setOpen(false)}
        shareUrl={process.env.NEXT_APP_WEB_HOST}
        description="Planbowl: More than a Preparedness Guide, it’s an investment in the safety of you and your loved ones."
        shareImage={`https://res-gpts.pumpsoul.com/img/share_img.webp`}
      />
    </div>
  );
};

export default UserContent;
